'use client';

import { useAnalytics } from '@/app/components/Analytics';
import { useTranslation } from '@/app/i18n/client';
import { Route } from '@/lib/pageroutes';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import Link from 'next/link';

export const DashboardSignIn = () => {
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);
  const { t } = useTranslation();
  const analytics = useAnalytics();

  return (
    <nav
      className={`${
        partnerSettings.encore ? 'bg-core-4' : 'bg-core-2'
      } h-10 flex items-center justify-center text-core-7 text-body-3-light`}
      aria-label="Navigation for active customers"
    >
      {t('dashboard-sign-in-text')}&nbsp;
      <Link
        href={Route.DASHBOARD}
        className="group text-body-3 text-core-7 whitespace-nowrap hover:text-core-8 duration-75"
        onClick={() => {
          analytics?.trackButtonClick(
            partner,
            'Already have funding?',
            'ExistingFunding-SignIn',
          );
        }}
      >
        <span className="inline-block transform transition-transform pr-2">
          {t('dashboard-sign-in-link-text')}
          <svg
            width="20"
            height="11"
            viewBox="0 0 14 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="inline ml-1 transition-colors duration-75"
          >
            <path
              d="M6.53678 1L12 5.59491M12 5.59491L6.53678 10.2284M12 5.59491L0.673819 5.59492"
              stroke="#6C728A"
              strokeWidth="1.6"
              className="group-hover:stroke-core-7 duration-75 group-hover:translate-x-1"
            />
          </svg>
        </span>
      </Link>
    </nav>
  );
};
