import { ParsedText } from '@/app/components/ParsedText/ParsedText';
import './Dropdown.css';

type DropdownProps = {
  index: number;
  openIndex: number | null;
  summary: string;
  content: string | React.ReactNode;
  toggleDropdown: (index: number) => void;
  summaryClassName?: string;
};

export const Dropdown = ({
  index,
  openIndex,
  summary,
  content,
  toggleDropdown,
  summaryClassName,
}: DropdownProps) => {
  const isOpen = openIndex === index;

  return (
    <details
      open={isOpen}
      key={index}
      className="w-full max-w-lg bg-opacity-0 group faqDetails"
    >
      <summary
        className="flex items-center justify-between cursor-pointer border-b border-core-4"
        onClick={(event) => {
          event.preventDefault();
          toggleDropdown(index);
        }}
      >
        <span
          className={
            summaryClassName ??
            'text-core-7 select-none font-light group-open:font-bold'
          }
        >
          {summary}
        </span>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            className="transition-transform duration-300 group-open:rotate-180"
          >
            <path
              d="M8.00048 8.77873L11.3005 5.47873L12.2431 6.4214L8.00048 10.6641L3.75781 6.4214L4.70048 5.47873L8.00048 8.77873Z"
              className="fill-core-7"
            />
          </svg>
        </div>
      </summary>
      {typeof content === 'string' ? (
        <div
          className="text-body-3-light bg-core-3 rounded-xl mt-3 origin-top group-open:animate-slideDown"
          key={`${isOpen}_${index}`}
        >
          <div className="text-core-7 p-6 mb-6">
            <ParsedText htmlString={content} />
          </div>
        </div>
      ) : (
        content
      )}
    </details>
  );
};
