'use client';

import { Modal } from '@/app/components/Modal/Modal';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';

interface PopupProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onBackgroundClick?: () => void;
  onAfterClose?: () => void;
  style: {};
  heading?: React.ReactNode;
  body?: React.ReactNode;
  element?: React.ReactNode;
  icon?: React.ReactNode;
  closeText?: string;
  closeTextStyles?: string;
}

export const Popup = ({
  isOpen,
  onRequestClose,
  onBackgroundClick,
  onAfterClose,
  style,
  closeText,
  closeTextStyles,
  icon,
  heading,
  body,
  element,
}: PopupProps) => {
  const partner = usePartner();
  const partnerSettings = getPartnerSettings(partner);

  const defaultCloseTextClassnames =
    partnerSettings.encore || partnerSettings.prepopulatedJourney
      ? 'transition-colors duration-200 w-full tablet:min-h-10 min-h-9 text-body-4 tablet:text-body-3 flex items-center justify-center relative group focus:text-core-8 focus:underline focus:underline-offset-2 focus:outline-none text-core-7 hover:text-core-8 hover:underline hover:underline-offset-2'
      : 'text-core-7 cursor-pointer font-light hover:underline';
  const closeTextClassnames = closeTextStyles || defaultCloseTextClassnames;

  return (
    <div className="max-w-[30rem]">
      <Modal
        isOpen={isOpen}
        onRequestClose={onBackgroundClick || onRequestClose}
        style={style}
        onAfterClose={onAfterClose}
      >
        {icon}
        {heading}
        {body}
        {element}
        <button
          className={closeTextClassnames}
          id="Modal Close"
          onClick={onRequestClose}
        >
          {closeText}
        </button>
      </Modal>
    </div>
  );
};
