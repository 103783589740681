'use client';

import { useAnalytics } from '@/app/components/Analytics';
import { useTranslation } from '@/app/i18n/client';
import { usePartner } from '@/lib/partners/usePartner/client';
import { Input } from '@/app/components/Input';
import { Popup } from '../Popup';
import { useRef, useState } from 'react';
import { generateUniqueId } from '@/lib/utils/generateUniqueId';
import { EventTypes, UserEvents, useLogEvent } from '@/app/hooks/useLogEvent';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { useParams } from 'next/navigation';
import { validateForm } from '@/lib/utils/formValidationHelpers';
import { Button } from '@/app/components/Button/Button';
import { EmailSent } from '../EmailSentIcon/EmailSent';
import { ParsedText } from '@/app/components/ParsedText/ParsedText';

export const ReturnToJourneyCTA = ({ id }: { id: string }) => {
  const partner = usePartner();
  const { t } = useTranslation();
  const analytics = useAnalytics();
  const form = useRef<HTMLFormElement>(null);
  const [showAlreadyStartedModal, setShowAlreadyStartedModal] =
    useState<boolean>(false);
  const [showCheckYourInboxModal, setShowCheckYourInboxModal] =
    useState<boolean>(false);
  const [emailValue, setEmailValue] = useState<string>('');
  const logError = useLogEvent(EventTypes.ERROR);
  const logEvent = useLogEvent(EventTypes.USER_EVENT);
  const params = useParams();

  const openAlreadyStartedModal = () => {
    setShowAlreadyStartedModal(true);
  };

  const closeAlreadyStartedModal = () => {
    setShowAlreadyStartedModal(false);
  };

  const closeCheckYourInboxModal = () => {
    setShowCheckYourInboxModal(false);
  };

  const submitEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const valid = validateForm(e.currentTarget);

    if (valid) {
      logEvent(UserEvents.ALREADY_STARTED_APPLICATION_SUBMIT_EMAIL);
      setShowAlreadyStartedModal(false);
      setShowCheckYourInboxModal(true);
      const host_url = getEnvVar('NEXT_PUBLIC_HOST_URL');
      const partnerURLHost = host_url?.replace('{partner}', partner);

      const traceId = generateUniqueId();
      const spanId = generateUniqueId();

      try {
        const response = await fetch(
          `${partnerURLHost}/api/${partner}/return`,
          {
            method: 'POST',
            headers: {
              'X-Datadog-Trace-Id': traceId,
              'X-Datadog-Parent-Id': spanId,
              'X-Datadog-Origin': 'local',
              'X-Datadog-Sampling-Priority': '1',
            },
            body: JSON.stringify({ email: emailValue, lang: params?.lang }),
          },
        );

        if (!response.ok) {
          const text = await response.text();
          logError(new Error(text), traceId, spanId);
        }
      } catch (error: any) {
        logError(error, traceId, spanId);
      }
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
  ) => {
    const targetField = e.target || e.detail.target;
    const value = targetField.value;
    setEmailValue(value);
  };

  const customPopupStyles = {
    content: {
      zIndex: 20,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '460px',
      height: '420px',
      background: 'white',
      borderRadius: '12px',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    overlay: {
      zIndex: 20,
      backgroundColor: 'rgba(14, 11, 35, 0.75)',
    },
  };

  return (
    <>
      <div
        className="flex items-center justify-center mt-6 laptop:mt-8"
        aria-label="navigation to return to an application"
      >
        <button
          className="text-center text-core-7 text-body-2 whitespace-nowrap duration-75"
          onClick={() => {
            analytics?.trackButtonClick(
              partner,
              t('return-to-journey-CTA'),
              id,
            );
            openAlreadyStartedModal();
            logEvent(UserEvents.ALREADY_STARTED_APPLICATION);
          }}
        >
          <div className="hover:underline hover:text-core-8">
            {t('return-to-journey-CTA')}
          </div>
        </button>
      </div>
      <Popup
        isOpen={showAlreadyStartedModal}
        onRequestClose={closeAlreadyStartedModal}
        style={customPopupStyles}
        closeText={t('return-to-journey-popup-cancel')}
        heading={
          <h2 className="text-lg font-medium mb-4 pt-5 text-center">
            {t('return-to-journey-popup-heading')}
          </h2>
        }
        element={
          <>
            <div className="block font-light text-base w-[20rem] lmobile:w-fit lmobile:pl-6 lmobile:pr-6 text-center">
              <ParsedText
                className="mb-6"
                htmlString={t('return-to-journey-popup-content')}
              />
              <form
                className="mt-6 items-center"
                ref={form}
                onSubmit={submitEmail}
                noValidate
              >
                <div className="pb-10">
                  <Input
                    type="email"
                    id="RtjmailInput"
                    name="RtjmailInput"
                    onChange={handleChange}
                    popup={true}
                    placeholder={t('return-to-journey-popup-email-placeholder')}
                    required
                    errorMessage={t('go-fund-yourself-email-error-message')}
                  />
                </div>
                <div className="mb-5 w-full">
                  <Button
                    mixpanelButtonText="returnToJourney"
                    id="returnToJourney"
                    type="submit"
                  >
                    {t('return-to-journey-popup-button')}
                  </Button>
                </div>
              </form>
            </div>
          </>
        }
      />
      <Popup
        isOpen={showCheckYourInboxModal}
        onRequestClose={closeCheckYourInboxModal}
        style={customPopupStyles}
        icon={
          <span className="[&_svg]:inline h-[54px] w-[54px] mt-5 text-primary">
            <EmailSent />
          </span>
        }
        heading={
          <h2 className="text-lg font-medium mb-4 mt-8 text-center text-core-8">
            {t('return-to-journey-popup-submitted-heading')}
          </h2>
        }
        body={
          <div className="block font-light text-base mb-5 w-[20rem] lmobile:w-fit lmobile:pl-6 lmobile:pr-6 text-center">
            <ParsedText
              htmlString={t('return-to-journey-popup-submitted-content')}
            />
          </div>
        }
        element={
          <div className="mt-12 lmobile:mt-16 mb-5 w-[20rem]">
            <Button
              mixpanelButtonText="returnToJourneySubmitted"
              id="returnToJourneySubmitted"
              onclick={closeCheckYourInboxModal}
            >
              {t('return-to-journey-popup-submitted-button')}
            </Button>
          </div>
        }
      />
    </>
  );
};
