import { Button } from '@/v2Components/Button/Button';
import { Button as ButtonV1 } from '@/app/components//Button';
import { useAnalytics } from '@/app/components/Analytics/hooks/useAnalytics';
import { LanguageSelector } from '@/app/components/LanguageSelector';
import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import Image from 'next/image';
export interface BannerProps {
  activeAnchor: string;
  setAnchor: (anchor: string) => void;
}

export const Banner = ({ activeAnchor, setAnchor }: BannerProps) => {
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);
  const { t } = useTranslation();
  const analytics = useAnalytics();
  const multipleLanguages =
    Array.isArray(partnerSettings?.languageLocale) &&
    partnerSettings?.languageLocale?.length > 1;

  return (
    <nav className="px-2 lmobile:px-4  h-20 flex justify-between align-middle mx-auto max-w-[1440px] w-full">
      <Image
        src={`${getEnvVar('NEXT_PUBLIC_CDN')}${partnerSettings.imagesPath}${
          partnerSettings.images.logo
        }`}
        width={300}
        alt={
          partnerSettings.logoALTText || partnerSettings.displayName || partner
        }
        height={40}
        priority
        className={`z-20 max-h-[40px] my-auto w-auto  ${
          multipleLanguages
            ? 'max-w-[110px] mobile:max-w-[120px] lmobile:max-w-[180px]'
            : 'max-w-[175px] mobile:max-w-[190px] lmobile:max-w-[220px]'
        } tablet:max-w-[240px] laptop:max-w-[200px] desktop:max-w-[240px] mr-[20px] mobile:mr-[20px] lmobile:mr-[30px] desktop:mr-[50px]`}
      />
      <div
        className={`z-20 hidden flex-1 justify-between mr-[20px]  max-w-[800px] ${
          multipleLanguages
            ? 'desktop:flex desktop:mr-4'
            : 'laptop:flex desktop:mr-[40px]'
        }`}
      >
        <a
          href="#overview"
          className="relative z-30 p-2 text-center whitespace-nowrap text-core-7 border border-t-0 border-x-0 border-b-0 h-20 text-body-3-light hidden laptop:flex"
          onClick={() => {
            analytics &&
              analytics.trackButtonClick(
                partner,
                t('menu-option-overview'),
                'LandingPageBanner-Overview',
              );
            setAnchor('overview');
          }}
        >
          <span className="my-auto inline-block ">
            {t('menu-option-overview')}
          </span>
          {activeAnchor === 'overview' && (
            <span className="h-1 absolute bottom-0 -left-2 -right-2  bg-primary overflow-hidden animate-growX origin-top-left "></span>
          )}
        </a>
        <a
          href="#revenue-finance"
          className=" relative  p-2 text-center whitespace-nowrap text-core-7 h-20 text-body-3-light hidden laptop:flex"
          onClick={() => {
            analytics &&
              analytics.trackButtonClick(
                partner,
                t('menu-option-revenue-finance'),
                'LandingPageBanner-RevenueFinance',
              );
            setAnchor('revenue-finance');
          }}
        >
          <span className="my-auto inline-block ">
            {t('menu-option-revenue-finance')}
          </span>
          {activeAnchor === 'revenue-finance' && (
            <span className="h-1 absolute bottom-0 -left-2 -right-2 bg-primary overflow-hidden animate-growX origin-top-left"></span>
          )}
        </a>
        <a
          href="#funding-calculator"
          className="relative p-2 text-center whitespace-nowrap text-core-7 h-20 text-body-3-light hidden laptop:flex"
          onClick={() => {
            analytics &&
              analytics.trackButtonClick(
                partner,
                t('menu-option-funding-calculator'),
                'LandingPageBanner-FundingCalculator',
              );
            setAnchor('funding-calculator');
          }}
        >
          <span className="my-auto inline-block">
            {t('menu-option-funding-calculator')}
          </span>
          {activeAnchor === 'funding-calculator' && (
            <span className="h-1 absolute bottom-0 -left-2 -right-2 bg-primary overflow-hidden animate-growX origin-top-left"></span>
          )}
        </a>
        <a
          href="#our-customers"
          className="relative p-2 text-center whitespace-nowrap text-core-7 h-20 text-body-3-light hidden laptop:flex"
          onClick={() => {
            analytics &&
              analytics.trackButtonClick(
                partner,
                t('menu-option-our-customers'),
                'LandingPageBanner-OurCustomers',
              );
            setAnchor('our-customers');
          }}
        >
          <span className="my-auto inline-block">
            {t('menu-option-our-customers')}
          </span>
          {activeAnchor === 'our-customers' && (
            <span className="h-1 absolute bottom-0 -left-2 -right-2 bg-primary overflow-hidden animate-growX origin-top-left"></span>
          )}
        </a>
        <a
          href="#faqs"
          className="relative p-2 text-center whitespace-nowrap text-core-7 h-20 text-body-3-light hidden laptop:flex"
          onClick={() => {
            analytics &&
              analytics.trackButtonClick(
                partner,
                t('menu-option-faqs'),
                'LandingPageBanner-Faqs',
              );
            setAnchor('faqs');
          }}
        >
          <span className="my-auto inline-block">{t('menu-option-faqs')}</span>
          {activeAnchor === 'faqs' && (
            <span className="h-1 absolute bottom-0 -left-2 -right-2 bg-primary overflow-hidden animate-growX origin-top-left"></span>
          )}
        </a>
      </div>

      {multipleLanguages && (
        <div className="p-2 pl-0 text-center whitespace-nowrap text-core-7 h-20 text-body-3-light flex justify-end tablet:flex-1 desktop:flex-initial z-10">
          <span className="my-auto inline-block">
            <LanguageSelector />
          </span>
        </div>
      )}

      <div
        className={`p-2 pl-0 flex-1 mr-0 items-center justify-end laptop:flex-0 laptop:items-center max-w-[240px] ${
          multipleLanguages ? 'laptop:max-w-[240px]' : 'laptop:max-w-[160px]'
        } desktop:max-w-[240px] flex text-sm z-20`}
      >
        {!partnerSettings.encore ? (
          <ButtonV1
            implementAsAnchor={true}
            href="#get-funded"
            id={'BannerButton-GetFunded'}
            mixpanelButtonText={'Get Funded'}
            onclick={() => setAnchor('get-funded')}
          >
            {t('menu-button')}
          </ButtonV1>
        ) : (
          <Button
            implementAs="NextLink"
            href="#get-funded"
            id={'BannerButton-GetFunded'}
            mixpanelButtonText={'Get Funded'}
            onClick={() => setAnchor('get-funded')}
          >
            {t('menu-button')}
          </Button>
        )}
      </div>
    </nav>
  );
};
