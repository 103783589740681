'use client';

import { useUTMParams } from '@/app/components/Analytics/hooks/useUTMParams';
import { Button } from '@/v2Components/Button/Button';
import { Button as ButtonV1 } from '@/app/components//Button';
import { Input } from '@/app/components/Input';
import { ParsedText } from '@/app/components/ParsedText';
import { STORAGE_KEY } from '@/app/contexts/global/actions';
import { useTranslation } from '@/app/i18n/client';
import { applicationStartingPoint as APP_START_URL } from '@/lib/pageroutes';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import persistenceService from '@/lib/utils/persistenceService';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { applicationRoutePaths } from '../../../(PrepopulatedJourney)/lib/pageroutes';
import { ReturnToJourneyCTA } from '../../ReturnToJourneyCTA';

export const HeroEmailCapture = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);
  const allowReturnToJourney = partnerSettings.allowReturnToJourney;
  const utmParams = useUTMParams();

  const host_url = getEnvVar('NEXT_PUBLIC_HOST_URL');

  if (!host_url) {
    return (
      <h1 className="text-error-1 font-bold text-xl">
        Error: HOST_URL is not set
      </h1>
    );
  }

  const errorAttribute = 'data-error';
  const revalidate = (e: Event) => {
    validateElement(e.target as HTMLInputElement, true);
  };

  const validateElement = (el: HTMLInputElement, inErrorState?: boolean) => {
    const elIsValid = el.checkValidity();

    if (!elIsValid) {
      el.setAttribute(errorAttribute, 'true');

      if (!inErrorState) {
        el.addEventListener('keyup', (e) => revalidate(e));
      }
    } else if (el.hasAttribute(errorAttribute)) {
      el.removeAttribute(errorAttribute);
      el.removeEventListener('keydown', revalidate);
    }

    return elIsValid;
  };

  const validateForm = (form: HTMLFormElement): boolean => {
    const formEls = form.elements;

    let valid = true;
    Array.from(formEls).forEach((el, i) => {
      const elIsValid = validateElement(el as HTMLInputElement);

      if (!elIsValid && valid) {
        valid = false;
      }
    });

    return valid;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const valid = validateForm(e.currentTarget);

    if (valid) {
      const formData = new FormData(e.currentTarget);
      const storageData: any = {
        offersPayload: {
          application: {},
          applicants: [{ email_address: formData.get('EmailCaptureInput') }],
          marketing: Object.entries(utmParams).reduce((acc, [term, value]) => {
            return { ...acc, [term]: value };
          }, {}),
        },
        utm_campaign: utmParams.marketing_campaign,
      };

      if (utmParams.marketing_term && !partnerSettings?.disallowMIDinURL) {
        storageData.offersPayload.application.merchant_id =
          utmParams.marketing_term;
      }

      persistenceService.set(STORAGE_KEY, storageData);
      setEmailSent(true);
      router.push(
        partnerSettings.encore
          ? applicationRoutePaths.ENCORE_START_URL
          : APP_START_URL,
      );
    }
  };

  return (
    <>
      <form method="POST" autoComplete="off" noValidate onSubmit={handleSubmit}>
        <label htmlFor="HeroSectionInput" className="sr-only">
          {t('hero-email-placeholder')}
        </label>
        <Input
          type="email"
          placeholder={t('hero-email-placeholder')}
          id="HeroSectionInput"
          required
          errorMessage={t('hero-email-error-message')}
          name="EmailCaptureInput"
        />
        {!partnerSettings.encore ? (
          <ButtonV1
            type="submit"
            includeArrow={true}
            id={'HeroSectionButton-SubmitEmail'}
            mixpanelButtonText={t('hero-cta')}
            isLoading={emailSent}
          >
            {t('hero-cta')}
          </ButtonV1>
        ) : (
          <Button
            type="submit"
            id={'HeroSectionButton-SubmitEmail'}
            mixpanelButtonText={t('hero-cta')}
            loading={emailSent}
          >
            {t('hero-cta')}
          </Button>
        )}
      </form>
      {allowReturnToJourney ? (
        <>
          <ReturnToJourneyCTA id="ReturnToJourney-HeroSection" />
          <p className="text-body-5 text-center mt-3 mb-8 laptop:mt-7 text-core-7">
            <ParsedText
              htmlString={t('hero-disclaimer').replace(
                '{privacy-policy-url}',
                partnerSettings['privacy-policy-url'],
              )}
            />
          </p>
        </>
      ) : (
        <p className="text-body-5 leading-5 text-center mt-4 mb-8 laptop:mt-8 text-core-7">
          <ParsedText
            htmlString={t('hero-disclaimer').replace(
              '{privacy-policy-url}',
              partnerSettings['privacy-policy-url'],
            )}
          />
        </p>
      )}
    </>
  );
};
