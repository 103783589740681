'use client';

import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { Carousel } from '../Carousel';
import Image from 'next/image';
import { usePartner } from '@/lib/partners/usePartner/client';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import classNames from 'classnames';

export const OurCustomers = () => {
  const partner = usePartner();
  const { t } = useTranslation();
  const partnerSettings: any = getPartnerSettings(partner);

  const Slide1 = (
    <div
      className={classNames(
        'relative mx-auto max-w-text-column w-full bg-core-1 rounded-3xl',
        { 'laptop:drop-shadow-ds1': !partnerSettings.encore },
      )}
    >
      <div className="relative w-full min-h-[300px] ">
        <Image
          className="w-full rounded-tl-3xl rounded-tr-3xl object-cover"
          src={`${getEnvVar('NEXT_PUBLIC_CDN')}${partnerSettings.imagesPath}${
            partnerSettings.images.ourCustomers1
          }`}
          alt=""
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          fill
        />
      </div>
      <div className="p-9 flex flex-col flex-1 text-sm lmobile:text-base min-h-[286px]">
        <p
          className={`flex-1 ${
            partnerSettings.encore
              ? 'text-body-3-light text-core-8'
              : 'font-light leading-7 flex-1'
          }`}
        >
          {t('our-customers-reference1')}
        </p>
        <p
          className={`${
            partnerSettings.encore
              ? 'text-body-3 text-core-8'
              : 'font-light leading-7 mt-8 bold'
          }`}
        >
          {t('our-customers-referee1-name')}
        </p>
        <p
          className={`${
            partnerSettings.encore
              ? 'text-body-3 text-core-7'
              : 'font-light leading-7 text-core-7 '
          }`}
        >
          {t('our-customers-referee1-company')}
        </p>
      </div>
    </div>
  );

  const Slide2 = (
    <div
      className={classNames(
        'relative mx-auto max-w-text-column w-full bg-core-1 rounded-3xl',
        { 'laptop:drop-shadow-ds1': !partnerSettings.encore },
      )}
    >
      <div className="relative w-full min-h-[300px]">
        <Image
          fill
          className="w-full rounded-tl-3xl rounded-tr-3xl object-cover"
          src={`${getEnvVar('NEXT_PUBLIC_CDN')}${partnerSettings.imagesPath}${
            partnerSettings.images.ourCustomers2
          }`}
          alt=""
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        />
      </div>
      <div className="p-9 flex flex-col flex-1 text-sm lmobile:text-base min-h-[286px]">
        <p
          className={`flex-1 ${
            partnerSettings.encore
              ? 'text-body-3-light text-core-8'
              : 'font-light leading-7 flex-1'
          }`}
        >
          {t('our-customers-reference2')}
        </p>
        <p
          className={`${
            partnerSettings.encore
              ? 'text-body-3 text-core-8'
              : 'font-light leading-7 mt-8 bold'
          }`}
        >
          {t('our-customers-referee2-name')}
        </p>
        <p
          className={`${
            partnerSettings.encore
              ? 'text-body-3 text-core-7'
              : 'font-light leading-7 text-core-7 '
          }`}
        >
          {t('our-customers-referee2-company')}
        </p>
      </div>
    </div>
  );

  return (
    <div className="grid grid-cols-12 gap-4 mb-24 ">
      <div className="col-span-12 text-center mt-16 lmobile:mt-12 ">
        <h2
          className={`${
            partnerSettings.encore
              ? 'text-header-3 laptop:text-header-2'
              : 'text-header-lg-mobile lmobile:text-header-lg'
          } mx-auto max-w-4xl`}
        >
          {t('our-customers-header')}
        </h2>
        <p
          className={`max-w-xl mx-auto  ${
            partnerSettings.encore
              ? 'text-body-3-light mt-k-10 mb-k-12'
              : 'font-light leading-7 text-sm lmobile:text-base mt-10 lmobile:mt-14 mb-16'
          }`}
        >
          {t('our-customers-text')}
        </p>
      </div>
      <div className="col-span-12 laptop:col-span-6 hidden laptop:block">
        {Slide1}
      </div>
      <div className="col-span-12 laptop:col-span-6 hidden laptop:block">
        {Slide2}
      </div>
      <div className="col-span-12 laptop:hidden">
        <Carousel items={[Slide1, Slide2]} id="carousel" />
      </div>
    </div>
  );
};
